<template>
  <div class="login-form">
      <div class="welcome-text">
        Please complete to create your account
      </div>
      <form name="form" @submit.prevent="handleSubmit">
        <div class="flex-row-centered">
          <div class="underline-input flex-grow-1">
            <!--          <label for="email">Email</label>-->
            <b-form-input
                v-model="firstName"
                v-validate="'required'"
                type="text"
                placeholder="First Name"
                class="form-control"
                name="firstName"
                :state="hasError('firstName')"
            />
          </div>

          <div class="underline-input flex-grow-1">
            <!--          <label for="email">Email</label>-->
            <b-form-input
                v-model="lastName"
                v-validate="'required'"
                type="text"
                placeholder="Last Name"
                class="form-control"
                name="lastName"
                :state="hasError('lastName')"
            />
          </div>
        </div>
        <div class="flex-row-centered">
          <div class="underline-input flex-grow-1">
            <!--          <label for="email">Email</label>-->
            <b-form-input
                v-model="email"
                v-validate="'required|email'"
                type="email"
                placeholder="Email Address"
                class="form-control"
                name="email"
                :state="hasError('email')"
            />
          </div>
        </div>
        <div class="flex-row-centered">
          <div class="underline-input flex-grow-1">
            <!--          <label for="email">Email</label>-->
            <b-form-input
                v-model="password"
                v-validate="'required'"
                type="password"
                placeholder="Password"
                class="form-control"
                name="password"
                :state="hasError('password')"
            />
          </div>
        </div>
        <div class="flex-row-centered">
          <div class="underline-input flex-grow-1">
            <!--          <label for="email">Email</label>-->
            <b-form-input
                v-model="passwordConfirm"
                v-validate="'required|confirmed:password'"
                type="password"
                placeholder="Confirm Password"
                class="form-control"
                name="confirmPassword"
                :state="hasError('confirmPassword')"
            />
          </div>
        </div>

        <div class="flex-row mt-3">
          <b-form-checkbox>I agree with <router-link to="/terms">terms and conditions</router-link></b-form-checkbox>
        </div>
        <div class="validation-errors" >
          <ul>
            <li v-for="error in errors.all()" v-bind:key="error">{{ error }}</li>
          </ul>
        </div>
        <div class="flex-row-centered">
          <button class="rebutton" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Sign Up</span>
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </form>
    <div class="footer">
      <router-link to="/terms">Terms of Use</router-link>
      |
      <router-link to="/privacy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      agreeToTerms: false,
      loading: false,
      submitted: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/dashboards');
    // }
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.submitted = true
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          console.log(this.errors)
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/dashboards');
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    },
    hasError(fieldName) {
      if (!this.errors || !this.submitted) {
        return null
      } else {
        console.log(this.errors)
        console.log(!this.errors.has(fieldName))
        return (!this.errors.has(fieldName))
      }
    }
  },
};
</script>

<style scoped>

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%
}

.welcome-text {
  text-align: center;
  font-weight: bold;
  color: #026cc0;
  margin-bottom: 10%;
}

label {
  display: block;
  margin-top: 10px;
}

.validation-errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b71e00;
  margin: 2em;
}

.footer {
  display: block;
  position: fixed;
  left: 75%;
  transform: translate(-50%, 0);
  bottom: 7.5%;
  text-align: center;
  font-size: 0.75em;
}

</style>
